import { BackendError, ClientError } from '@/store/errors';

export enum AudienceErrorCodes {
  AUDIENCE_ENTITY_CREATION = 'AUDIENCE_ENTITY_CREATION',
  AUDIENCE_PERSISTENCE_CREATION = 'AUDIENCE_PERSISTENCE_CREATION',
  NEXUS_AUDIENCE_TRAINING = 'NEXUS_AUDIENCE_TRAINING',
  NEXUS_DELETE_OPERATION = 'NEXUS_DELETE_OPERATION',
  AUDIENCE_NOT_FOUND = 'AUDIENCE_NOT_FOUND',
  AUDIENCE_STATISTICS = 'AUDIENCE_STATISTICS',
  DELETE_NOT_ALLOWED = 'DELETE_NOT_ALLOWED',
  USER_NOT_ADMIN = 'USER_NOT_ADMIN',
  DEALS_NOT_FOUND = 'DEALS_NOT_FOUND',
  XANDR_DEALS_CREATION = 'XANDR_DEALS_CREATION',
  XANDR_SEGMENTS_CREATION = 'XANDR_SEGMENTS_CREATION',
  XANDR_DELETE_OPERATION = 'XANDR_DELETE_OPERATION',
  XANDR_SEGMENT_DEALS_CREATION = 'XANDR_SEGMENT_DEALS_CREATION',
  CURATOR_SEATS_NOT_FOUND = 'CURATOR_SEATS_NOT_FOUND',
  MEDIAGRID_SEGMENTS_CREATION = 'MEDIAGRID_SEGMENTS_CREATION',
  PUBMATIC_SEGMENTS_CREATION = 'PUBMATIC_SEGMENTS_CREATION',
  AUDIENCE_IS_REQUIRED = 'AUDIENCE_IS_REQUIRED',
  FEED_AUDIENCE_CREATION = 'FEED_AUDIENCE_CREATION',
  NO_AUDIENCE_DEALS = 'NO_AUDIENCE_DEALS',
  ACCOUNT_NOT_FOUND = 'ACCOUNT_NOT_FOUND',
  FAILED_CREATE_REPORT = 'FAILED_CREATE_REPORT',
  INVALID_AUDIENCE_TYPE = 'INVALID_AUDIENCE_TYPE',
  UNAUTHORIZED = 'UNAUTHORIZED',
  BUYER_PLATFORM_SEAT_NOT_FOUND = 'BUYER_PLATFORM_SEAT_NOT_FOUND',
  AUDIENCE_SCALE_NOT_THE_SAME = 'AUDIENCE_SCALE_NOT_THE_SAME',
  TOO_MANY_REQUESTS_ERROR = 'TOO_MANY_REQUESTS_ERROR',
  AUDIENCE_DEFINITION_NOT_FOUND = 'AUDIENCE_DEFINITION_NOT_FOUND',
  AUDIENCE_PLAN_NOT_FOUND = 'AUDIENCE_PLAN_NOT_FOUND',
  ATTRIBUTION_PIXEL_GROUP_NOT_FOUND = 'ATTRIBUTION_PIXEL_GROUP_NOT_FOUND',
  OPENAI_EXCEEDED_CURRENT_QUOTA_ERROR = 'OPENAI_EXCEEDED_CURRENT_QUOTA_ERROR',
  OPENAI_TOO_MANY_REQUESTS_ERROR = 'OPENAI_TOO_MANY_REQUESTS_ERROR',
  OPENAI_INTERNAL_SERVER_ERROR = 'OPENAI_INTERNAL_SERVER_ERROR',
  OPENAI_CONTENT_POLICY_VIOLATION_ERROR = 'OPENAI_CONTENT_POLICY_VIOLATION_ERROR',
  QUERY_GROUP_CREATION_ERROR = 'QUERY_GROUP_CREATION_ERROR',
}

export class CreateAudienceEntityError extends ClientError {
  constructor() {
    super(
      AudienceErrorCodes.AUDIENCE_ENTITY_CREATION,
      'The audience creation process has failed, the AirGrid team have been notified.'
    );
  }
}

export class CuratorSeatsNotFoundError extends ClientError {
  constructor(err?: BackendError) {
    super(
      AudienceErrorCodes.CURATOR_SEATS_NOT_FOUND,
      err
        ? err.response.data.message
        : 'Provided curator seats do not belong to the account or are not valid.'
    );
  }
}

export class MediaGridSegmentCreationError extends ClientError {
  constructor(err?: BackendError) {
    super(
      AudienceErrorCodes.MEDIAGRID_SEGMENTS_CREATION,
      err ? err.response.data.message : 'Failed to create a MediaGrid segment.'
    );
  }
}

export class PubmaticSegmentCreationError extends ClientError {
  constructor(err?: BackendError) {
    super(
      AudienceErrorCodes.PUBMATIC_SEGMENTS_CREATION,
      err ? err.response.data.message : 'Failed to create a Pubmatic segment.'
    );
  }
}

export class XandrDealsCreationError extends ClientError {
  constructor(err?: BackendError) {
    super(
      AudienceErrorCodes.XANDR_DEALS_CREATION,
      err
        ? err.response.data.message
        : 'Failed to create audience deals in Xandr.'
    );
  }
}

export class XandrSegmentsCreationError extends ClientError {
  constructor(err?: BackendError) {
    super(
      AudienceErrorCodes.XANDR_SEGMENTS_CREATION,
      err ? err.response.data.message : 'Failed to create segments in Xandr.'
    );
  }
}

export class AudiencePersistenceCreationError extends ClientError {
  constructor(err?: BackendError) {
    super(
      AudienceErrorCodes.AUDIENCE_PERSISTENCE_CREATION,
      err ? err.response.data.message : 'Failed to persist audience in the DB.'
    );
  }
}

export class QueryGroupCreationError extends ClientError {
  constructor(err?: BackendError) {
    super(
      AudienceErrorCodes.QUERY_GROUP_CREATION_ERROR,
      err ? err.response.data.message : 'Failed to create query group'
    );
  }
}

export class AudiencesNotFoundError extends ClientError {
  constructor(err?: BackendError) {
    super(
      AudienceErrorCodes.AUDIENCE_NOT_FOUND,
      err ? err.response.data.message : 'No audiences found for account.'
    );
  }
}

export class AudiencesStatisticsError extends ClientError {
  constructor(err?: BackendError) {
    super(
      AudienceErrorCodes.AUDIENCE_STATISTICS,
      err
        ? err.response.data.message
        : 'Could not get/map statistics for account.'
    );
  }
}

export class DeleteNotAllowedError extends ClientError {
  constructor(err?: BackendError) {
    super(
      AudienceErrorCodes.DELETE_NOT_ALLOWED,
      err
        ? err.response.data.message
        : 'Delete operation is not allowed in current environment.'
    );
  }
}

export class UserNotAdminError extends ClientError {
  constructor(err?: BackendError) {
    super(
      AudienceErrorCodes.USER_NOT_ADMIN,
      err
        ? err.response.data.message
        : 'Audience can be removed by admin user only.'
    );
  }
}

export class AudienceDealsNotFoundError extends ClientError {
  constructor(err?: BackendError) {
    super(
      AudienceErrorCodes.DEALS_NOT_FOUND,
      err ? err.response.data.message : 'Deals for the audience do not exist.'
    );
  }
}

export class XandrDeleteOperationError extends ClientError {
  constructor(err?: BackendError) {
    super(
      AudienceErrorCodes.XANDR_DELETE_OPERATION,
      err
        ? err.response.data.message
        : 'Xandr object for audience cannot be removed.'
    );
  }
}

export class NexusDeleteOperationError extends ClientError {
  constructor(err?: BackendError) {
    super(
      AudienceErrorCodes.NEXUS_DELETE_OPERATION,
      err ? err.response.data.message : 'Nexus audience cannot be removed'
    );
  }
}

export class AudienceIdRequiredError extends ClientError {
  constructor(err?: BackendError) {
    super(
      AudienceErrorCodes.AUDIENCE_IS_REQUIRED,
      err
        ? err.response.data.message
        : 'AudienceId is required for custom and taxonomy audiences'
    );
  }
}

export class FeedAudienceCreationError extends ClientError {
  constructor(err?: BackendError) {
    super(
      AudienceErrorCodes.FEED_AUDIENCE_CREATION,
      err
        ? err.response.data.message
        : 'Failed to create feed audience in Nexus'
    );
  }
}

export class NoDealsForAudienceError extends ClientError {
  constructor(err?: BackendError) {
    super(
      AudienceErrorCodes.NO_AUDIENCE_DEALS,
      err ? err.response.data.message : 'Failed to find audience deals.'
    );
  }
}

export class AccountNotFoundError extends ClientError {
  constructor(err?: BackendError) {
    super(
      AudienceErrorCodes.ACCOUNT_NOT_FOUND,
      err ? err.response.data.message : 'Failed to find account.'
    );
  }
}

export class ReportGenerationError extends ClientError {
  constructor(err?: BackendError) {
    super(
      AudienceErrorCodes.FAILED_CREATE_REPORT,
      err
        ? err.response.data.message
        : 'Failed to create a report for audience.'
    );
  }
}

export class XandrSegmentDealsCreationError extends ClientError {
  constructor(err?: BackendError) {
    super(
      AudienceErrorCodes.XANDR_SEGMENT_DEALS_CREATION,
      err
        ? err.response.data.message
        : 'No previous deals were found. Could not get Xandr segment id.'
    );
  }
}

export class InvalidAudienceType extends ClientError {
  constructor(err?: BackendError) {
    super(
      AudienceErrorCodes.INVALID_AUDIENCE_TYPE,
      err ? err.response.data.message : 'Audience type  is invalid.'
    );
  }
}

export class UnauthorizedFeedOrTaxonomyAudienceCreationError extends ClientError {
  constructor() {
    super(
      AudienceErrorCodes.UNAUTHORIZED,
      'The selected audience type cannot be created in the platform, the AirGrid team have been notified.'
    );
  }
}

export class BuyerPlatformSeatNotFoundError extends ClientError {
  constructor() {
    super(
      AudienceErrorCodes.BUYER_PLATFORM_SEAT_NOT_FOUND,
      'The provided buyer platform seat was not found, the AirGrid team have been notified.'
    );
  }
}

export class AudienceDefinitionScaleIsNotTheSame extends ClientError {
  constructor(err?: BackendError) {
    super(
      AudienceErrorCodes.AUDIENCE_SCALE_NOT_THE_SAME,
      err
        ? err.response.data.message
        : 'Audience definition scale is not the same.'
    );
  }
}

export class TooManyRequestsError extends ClientError {
  constructor(err?: BackendError) {
    super(
      AudienceErrorCodes.TOO_MANY_REQUESTS_ERROR,
      err
        ? err.response.data.message
        : 'Your request cannot be processed in time by the remote server. Please try again.'
    );
  }
}

export class AudienceDefinitionNotFound extends ClientError {
  constructor(audienceId: string, err?: BackendError) {
    super(
      AudienceErrorCodes.AUDIENCE_DEFINITION_NOT_FOUND,
      err
        ? err.response.data.message
        : `Definition not found. Passed audience id: ${audienceId}`
    );
  }
}

export class AudiencePlanNotFound extends ClientError {
  constructor(audienceId: string, err?: BackendError) {
    super(
      AudienceErrorCodes.AUDIENCE_PLAN_NOT_FOUND,
      err
        ? err.response.data.message
        : `Audience plan not found. Passed audience id: ${audienceId}`
    );
  }
}

export class AttributionPixelGroupNotFoundError extends ClientError {
  constructor(err?: BackendError) {
    super(
      AudienceErrorCodes.ATTRIBUTION_PIXEL_GROUP_NOT_FOUND,
      err
        ? err.response.data.message
        : `Cannot connect FEED audience and APG. APG with provided id does not exist.`
    );
  }
}

export class OpenAiCurrentQuotaExceededError extends ClientError {
  constructor() {
    super(
      AudienceErrorCodes.OPENAI_EXCEEDED_CURRENT_QUOTA_ERROR,
      '3rd party service is down, we are aware and working on a fix.'
    );
  }
}

export class OpenAiTooManyRequestsError extends ClientError {
  constructor() {
    super(
      AudienceErrorCodes.OPENAI_TOO_MANY_REQUESTS_ERROR,
      'Your request cannot be processed in time by the remote server. Please try again later.'
    );
  }
}

export class OpenAiInternalServerError extends ClientError {
  constructor() {
    super(
      AudienceErrorCodes.OPENAI_INTERNAL_SERVER_ERROR,
      '3rd party service has an internal error. Please try again later.'
    );
  }
}

export class OpenAiContentPolicyViolationError extends ClientError {
  constructor() {
    super(
      AudienceErrorCodes.OPENAI_CONTENT_POLICY_VIOLATION_ERROR,
      'Your request was rejected as a result of our safety system. Your prompt may contain text that is not allowed by our safety.'
    );
  }
}
