export const routes = Object.freeze({
  overview: { name: 'Overview', path: '/overview' },
  createAudience: {
    name: 'CreateAudience',
    path: '/audience/create',
  },
  audiences: {
    table: { name: 'AudiencesTable', path: '/audiences' },
    audience: { name: 'Audience', path: '/audiences/:id' },
    details: { name: 'Details', path: '/audiences/:id/details' },
    trends: { name: 'Analytics', path: '/audiences/:id/trends' },
    health: { name: 'Health', path: '/audiences/:id/health' },
    performance: { name: 'Performance', path: '/audiences/:id/performance' },
  },
  settings: { name: 'Settings', path: '/settings' },
  account: {
    info: { name: 'Account', path: '/settings/account' },
    auth: { name: 'Profile', path: '/settings/account/auth' },
    team: { name: 'Team', path: '/settings/team' },
  },
  login: { name: 'Login', path: '/login' },
  selectAccount: { name: 'SelectAccount', path: '/select-account' },
  switchAccount: { name: 'SwitchAccount', path: '/switch-account' },
  authRedirect: { name: 'AuthRedirect', path: '/auth-redirect' },
  admin: {
    settings: { name: 'AdminSettings', path: '/settings/admin' },
    accounts: { name: 'AdminAccounts', path: '/settings/admin/accounts' },
    audiences: { name: 'AdminAudiences', path: '/settings/admin/audiences' },
    templates: { name: 'AdminTemplates', path: '/settings/admin/templates' },
    create: { name: 'AdminCreation', path: '/settings/admin/create' },
    accountCreation: {
      name: 'AdminAccountCreationForm',
      path: '/settings/admin/create/account',
    },
    userCreation: {
      name: 'AdminUserCreationForm',
      path: '/settings/admin/create/user',
    },
    feedAudienceCreation: {
      name: 'AdminFeedAudienceCreationForm',
      path: '/settings/admin/create/audience/feed',
    },
    platformSeats: {
      name: 'AdminPlatformSeats',
      path: '/settings/admin/platform-seats',
    },
    deals: { name: 'AdminDeals', path: '/settings/admin/deals' },
    definitionManager: {
      name: 'AdminDefinitionManager',
      path: '/settings/admin/definition-manager',
    },
    ccsAgentLogs: {
      name: 'AdminCCSAgentLogs',
      path: '/settings/admin/ccs-agent-logs',
    },
    personas: {
      content: {
        name: 'AdminPersonas',
        path: '/settings/admin/personas',
      },
      details: {
        name: 'AdminPersonaDetails',
        path: '/settings/admin/personas/:id',
      },
    },
  },
  measure: {
    measure: { name: 'Measure', path: '/measure' },
    attributionPixelGroups: {
      list: {
        name: 'measure',
        path: '/measure/attribution-pixel-groups',
      },
      create: {
        name: 'attributionPixelGroupCreate',
        path: '/measure/attribution-pixel-group/create',
      },
      pixelGroup: {
        name: 'attributionPixelGroup',
        path: '/measure/attribution-pixel-group/:id',
      },
      details: {
        name: 'attributionPixelGroupDetails',
        path: '/measure/attribution-pixel-group/:id/details',
        title: 'Details',
      },
      analytics: {
        name: 'attributionPixelGroupAnalytics',
        path: '/measure/attribution-pixel-group/:id/analytics',
        title: 'Analytics',
      },
      reports: {
        name: 'attributionPixelGroupAggregateReports',
        path: '/measure/attribution-pixel-group/:id/reports',
        title: 'Reports',
      },
      debug: {
        name: 'attributionPixelGroupDebugReports',
        path: '/measure/attribution-pixel-group/:id/debug',
        title: 'Debug',
      },
    },
  },
  labs: {
    labs: { name: 'Labs', path: '/labs' },
    labsList: { name: 'LabsList', path: '/labs' },
    brandPersonas: {
      name: 'BrandPersonas',
      path: '/labs/brand-personas',
    },
    syntheticPanel: {
      name: 'SyntheticPanel',
      path: '/labs/synthetic-panel',
    },
    assistant: {
      name: 'Assistant',
      path: '/labs/assistant',
    },
    infiniteCanvas: {
      name: 'InfiniteCanvas',
      path: '/labs/infinite-canvas',
    },
    ccsAudiences: {
      name: 'CSSAudiences',
      path: '/labs/ccs-audiences',
    },
    nodeBuilder: {
      name: 'NodeBuilder',
      path: '/labs/node-builder',
    },
    // Released Labs START
    // These routes are kept to redirect users to the new routes
    personas: {
      name: 'Personas',
      path: '/labs/personas',
    },
    attributionPixelGroupList: {
      name: 'Attribution Pixel Group List',
      path: '/labs/attribution-pixel-group',
    },
    attributionPixelGroup: {
      name: 'Attribution Pixel Group',
      path: '/labs/attribution-pixel-group/:id',
    },
    // Released Labs END
  },
  publishers: {
    content: {
      name: 'Publishers',
      path: '/publishers',
    },
    create: {
      name: 'CreatePublisher',
      path: '/publishers/create',
    },
    details: {
      name: 'PublisherDetails',
      path: '/publishers/:id',
      title: 'Details',
    },
  },
  audiencePlan: { name: 'AudiencePlan', path: '/audience-plan/:id' },
  pageForbidden: { name: 'PageForbidden', path: '/page-forbidden' },
  pageNotFound: { name: 'PageNotFound', path: '/:catchAll(.*)*' },
});
