import { computed, ref } from 'vue';
import { defineStore } from 'pinia';
import {
  AdSize,
  Audience,
  AudienceDefinitionType,
  AudienceScale,
  AudienceStatus,
  AudienceType,
  BuyerPlatform,
  BuyerPlatformType,
  Deal,
  DealMetric,
  DeviceType,
  Geography,
  MediaType,
} from 'shared-types';

import { RequestAborted } from '@/store/errors';

import * as api from '@/store/api';
import {
  GetAudiencePlansResponse,
  Persona,
  PersonaImage,
  PersonaImageStatus,
} from '@/store/api';
import {
  copyDeep,
  getUserCountryFromLocalStorage,
  groupBy,
  isIncludedInPeriod,
  logger,
} from '@/utils';
import { useAccountStore } from '@/store/modules/account/account-store';
import { Language } from '@/utils/language';

type DealsState = Record<string, Deal[]>;

interface DealForm {
  audienceId: string;
  accountId: string;
  demandPlatformSeatIds: {
    code: string;
    buyerPlatform: BuyerPlatform;
    buyerPlatformType: BuyerPlatformType;
  }[];
}

export interface DefinitionInput {
  value: string;
  type: AudienceDefinitionType;
}

export interface UpdateAudienceRequest {
  accountId: string;
  audienceId: string;
  newOwnerId?: string;
  mediaType?: MediaType;
  metric?: DealMetric;
  status?: AudienceStatus;
  definition?: {
    add: string[];
    remove: string[];
  };
}

export interface UpdateDealRequest {
  accountId: string;
  dealId: number;
  geography?: Geography[];
  adSizes?: AdSize[] | null;
  deviceTypes?: DeviceType[] | null;
  viewabilityRate?: number;
  videoCompletionRate?: number;
  floorPrice?: number;
}

export enum TableTabName {
  Audience = 'Audience',
  Plan = 'Plan',
}

const getUserAudiences = (audiences: Audience[]) => {
  return audiences.filter((item) => item.status !== AudienceStatus.DELETED);
};

export const useAudienceStore = defineStore('audience-store', () => {
  const audiences = ref<Audience[] | undefined>();
  const deals = ref<DealsState>({} as DealsState);
  const isAudiencesLoaded = ref<boolean>(false);
  const fetchAudiencesError = ref<undefined | string>(undefined);
  const isAudiencesMetricsLoaded = ref<boolean>(false);

  const accountStore = useAccountStore();

  const createDeals = async (form: DealForm) => {
    try {
      const newDeals = await api.createDeals(form);

      const key = form.audienceId;

      if (!deals.value) {
        deals.value = {} as DealsState;
      }

      if (!deals.value[key]) {
        deals.value[key] = [];
      }

      const audienceDeals = deals.value[key] as Deal[];

      deals.value[key] = [...audienceDeals, ...newDeals];

      return newDeals;
    } catch (err) {
      logger.error(err);
      throw err;
    }
  };

  const updateDeal = async (payload: UpdateDealRequest, audienceId: string) => {
    await api.updateDeal(payload);

    if (!audiences.value || !deals.value) {
      throw new Error('Audience or Deal not loaded');
    }

    const {
      dealId,
      geography,
      adSizes,
      deviceTypes,
      viewabilityRate,
      videoCompletionRate,
      floorPrice,
    } = payload;
    const audienceIndex = audiences.value.findIndex(
      (audience) => audience.id === audienceId
    );

    const audienceKey = audienceId;
    const dealIndex = deals.value[audienceKey].findIndex(
      (deal) => deal.id === dealId
    );

    if (audienceIndex !== -1 && dealIndex !== -1) {
      if (geography) {
        deals.value[audienceKey][dealIndex].xandrGeography = geography;
      }

      if (adSizes) {
        deals.value[audienceKey][dealIndex].xandrAdSizes = adSizes;
      }

      if (deviceTypes) {
        deals.value[audienceKey][dealIndex].xandrDeviceTypes = deviceTypes;
      }

      if (viewabilityRate !== undefined) {
        deals.value[audienceKey][dealIndex].xandrViewabilityRate =
          viewabilityRate;
      }

      if (videoCompletionRate !== undefined) {
        deals.value[audienceKey][dealIndex].xandrVideoCompletionRate =
          videoCompletionRate;
      }

      if (floorPrice !== undefined) {
        deals.value[audienceKey][dealIndex].xandrFloorPrice = floorPrice;
      }
    } else {
      throw new Error(
        `Failed to update deal: Audience ${audienceId} or deal ${dealId} not found.`
      );
    }
  };

  const setAudienceDeals = async (audienceId: string) => {
    const account = useAccountStore().getAccount();

    const newDeals = await api.getAudienceDeals({
      accountId: account.id,
      audienceId,
    });

    const key = audienceId;
    if (!deals.value) {
      deals.value = {} as DealsState;
    }

    deals.value[key] = newDeals;
  };

  /**
   * Loads the audiences for the selected account.
   *
   * @param {Object} [options={}] - Options for loading audiences.
   * @param {boolean} [options.skipLoader=false] - Whether to skip the loading indicator.
   *  NOTE: This flag is needed here to prevent the appearance of the overlay loader
   *  when switching to the audience creation result screen during audience creation.
   * @returns {Promise<void>} A promise that resolves when the audiences are loaded.
   */
  const loadAudiences = async (
    options: { skipLoader?: boolean } = {}
  ): Promise<void> => {
    const { skipLoader = false } = options;

    try {
      if (!skipLoader) {
        isAudiencesLoaded.value = false;
      }

      fetchAudiencesError.value = undefined;

      const accountId = useAccountStore().getSelectedAccountId();

      if (accountId) {
        const newAudiences = await api.getAudiences({ accountId });
        audiences.value = newAudiences.filter((audience) => !audience.isHidden);
      }
    } catch (err) {
      logger.error(err);
      audiences.value = [];
      fetchAudiencesError.value = 'Failed to load audiences';
    } finally {
      isAudiencesLoaded.value = true;
    }
  };

  const unsetAudiences = () => {
    isAudiencesLoaded.value = false;
    fetchAudiencesError.value = undefined;
    audiences.value = undefined;
  };

  const setAudienceStatus = async (
    audienceId: string,
    status: AudienceStatus
  ) => {
    const account = useAccountStore().getAccount();

    if (!account.id || !status) {
      throw new Error(
        'Failed to set audience status: Missing account information.'
      );
    }

    const { id: accountId } = account;

    await api.updateAudienceStatus({
      accountId,
      audienceId,
      status,
    });

    if (audiences.value) {
      const audienceIndex = audiences.value.findIndex(
        (audience) => audience.id === audienceId
      );
      if (audienceIndex !== -1) {
        // NOTE: On BE we set notifiedInactiveAt to null, so we should do it on FE too
        audiences.value[audienceIndex].notifiedInactiveAt = null;
        audiences.value[audienceIndex].status = status;
      }
    }
  };

  const setAudienceScale = async (audienceId: string, scale: AudienceScale) => {
    const account = useAccountStore().getAccount();

    if (!account.id || !scale) {
      throw new Error(
        'Failed to set audience status: Missing account information.'
      );
    }

    const { id: accountId } = account;

    await api.updateAudienceScale({
      accountId,
      audienceId,
      scale,
    });
  };

  const deleteAudience = async (audienceId: string) => {
    await api.removeAudience(audienceId);

    if (audiences.value) {
      audiences.value = audiences.value.filter(
        (audience) => audience.id !== audienceId
      );
    }
  };

  const updateAudience = async (payload: UpdateAudienceRequest) => {
    await api.updateAudience(payload);

    if (audiences.value) {
      const { audienceId, status, newOwnerId, metric, mediaType } = payload;
      const audienceIndex = audiences.value.findIndex(
        (audience) => audience.id === audienceId
      );

      const key = audienceId;
      if (audienceIndex !== -1) {
        if (mediaType) {
          deals.value[key].forEach((d) => d.mediaType === mediaType);
        }

        if (metric) {
          deals.value[key].forEach((d) => (d.xandrMetric = metric));
        }

        if (status) {
          audiences.value[audienceIndex].status = status;
        }

        if (newOwnerId) {
          audiences.value[audienceIndex].userId = newOwnerId;
        }
      }
    }
  };

  const getAudienceDefinition = async (
    audienceId: string,
    accountId: string
  ) => {
    const audienceDefinition = await api.getAudienceDefinition(
      audienceId,
      accountId
    );
    return audienceDefinition;
  };

  const getAudienceDeals = (audienceId: string) => {
    return deals.value[audienceId];
  };

  const getLiveAudienceCount = () => {
    if (!audiences.value) return 0;

    const liveAudiences = audiences.value.filter(
      (i) => i.status === AudienceStatus.LIVE
    );

    const analyticsByAudienceId = groupBy(liveAudiences, 'id');
    return Object.keys(analyticsByAudienceId).length;
  };

  const getAudienceById = (audienceId: string): Audience => {
    if (!audiences.value) {
      throw new Error('Audiences are not loaded');
    }
    const result = audiences.value.find((a) => {
      return a.id === audienceId;
    });

    if (!result) {
      logger.error(
        `Unable to find audience by id. Passed audience id: ${audienceId}.`
      );
      throw new Error(
        `Unable to find audience by id. Passed audience id: ${audienceId}.`
      );
    }

    return result;
  };

  const getAudiencesAnalyticsWithinDays = (period: number) => {
    if (!audiences.value) throw new Error('Audiences are not loaded');

    const result = getUserAudiences(audiences.value);

    return result.map((row) => {
      const statistics = row.statistics.filter((s) =>
        isIncludedInPeriod(period, s.day)
      );
      return {
        ...row,
        statistics,
      };
    });
  };

  const getAdminOwnAudiences = () => {
    if (!audiences.value) throw new Error('Audiences are not loaded');

    return audiences.value.filter((audience) => {
      return audience.type !== AudienceType.TAXONOMY;
    });
  };

  const getAudiences = () => {
    if (!audiences.value) throw new Error('Audiences are not loaded');

    return getUserAudiences(audiences.value);
  };

  const getTaxonomyAudiences = () => {
    if (!audiences.value) throw new Error('Audiences are not loaded');

    return getUserAudiences(audiences.value).filter(
      (audience) => audience.type == AudienceType.TAXONOMY
    );
  };

  const getDoesAudienceExist = (audienceId: string | string[]) => {
    if (!audiences.value) throw new Error('Audiences are not loaded');

    const audience = audiences.value.find((a) => {
      return a.id === audienceId;
    });
    return audience !== undefined;
  };

  const setAudienceOwner = async (audienceId: string, newOwnerId: string) => {
    const account = useAccountStore().getAccount();

    if (!account.id || !newOwnerId) {
      throw new Error(
        'Failed to update audience owner: Missing account information.'
      );
    }

    const { id: accountId } = account;
    await api.updateAudienceOwner({
      accountId,
      newOwnerId,
      audienceId,
    });

    if (audiences.value) {
      const audienceIndex = audiences.value.findIndex(
        (audience) => audience.id === audienceId
      );

      if (audienceIndex !== -1) {
        audiences.value[audienceIndex].userId = newOwnerId;
      } else {
        throw new Error(
          `Failed to update audience owner: Audience ${audienceId} not found.`
        );
      }
    }
  };

  const setAccountDeals = async () => {
    const accountId = useAccountStore().getSelectedAccountId();
    const accountDeals = await api.getAccountDeals(accountId);
    deals.value = {} as DealsState;

    for (const deal of accountDeals) {
      const audienceKey = deal.audienceId;
      if (!deals.value[audienceKey]) {
        deals.value[audienceKey] = [deal];
      } else {
        deals.value[audienceKey].push(deal);
      }
    }
  };

  const getAccountDeals = computed(() => {
    if (!deals.value) {
      throw new Error('Failed to get deals.');
    }

    const accountDeals: Deal[] = [];
    for (const [, deal] of Object.entries(deals.value)) {
      copyDeep(deal).forEach((item: Deal) => {
        accountDeals.push(item);
      });
    }

    return accountDeals;
  });

  const getAudienceStatus = computed(() => (audienceId: string) => {
    if (!audiences.value) throw new Error('Audiences are not loaded');

    const audience = audiences.value.find((a) => {
      return a.id === audienceId;
    });

    if (!audience) {
      logger.error(
        `Unable to find audience by id. Passed audience id: ${audienceId}.`
      );
      throw new Error(
        `Unable to find audience by id. Passed audience id: ${audienceId}.`
      );
    }

    return audience.status;
  });

  const audiencePlans = ref<GetAudiencePlansResponse[] | null>(null);
  const isAudiencePlansLoading = ref(false);

  const loadAudiencePlans = async () => {
    isAudiencePlansLoading.value = true;
    try {
      const account = useAccountStore().getAccount();
      audiencePlans.value = await api.getAudiencePlans(account.id);
    } finally {
      isAudiencePlansLoading.value = false;
    }
  };

  const deleteAudiencePlan = async ({
    id,
    plannedAudienceId,
  }: {
    id: string;
    plannedAudienceId: string;
  }) => {
    if (audiencePlans.value) {
      await api.deleteAudiencePlan({ id, plannedAudienceId });
      audiencePlans.value = audiencePlans.value.filter(
        (plan) => plan.id !== id
      );
    }
  };

  const resetAudiencePlans = () => {
    audiencePlans.value = null;
  };

  const tableTabs = ref([
    {
      name: TableTabName.Audience,
      title: 'Audiences',
      visible: true,
    },
    {
      name: TableTabName.Plan,
      title: 'Plans',
      visible: false,
    },
  ]);

  const personaInput = ref<string | undefined>();
  const personas = ref<Persona[] | undefined>();
  const selectedPersonaId = ref<string | undefined>();

  const setPersonas = (newPersonas: Persona[]) => {
    personas.value = newPersonas;
  };

  const setSelectedPersonaId = (id: string) => {
    if (personas.value) {
      selectedPersonaId.value = id;
    }
  };

  const getSelectedPersonaAndImage = () => {
    if (
      personaImages.value &&
      personas.value &&
      selectedPersonaId.value !== undefined
    ) {
      const personaImage = personaImages.value.find(
        (item) => item.id === selectedPersonaId.value
      );

      const persona = personas.value.find(
        (item) => item.id === selectedPersonaId.value
      );

      if (!persona) {
        logger.error(
          `Cannot to find persona by id. Passed persona id: ${selectedPersonaId.value}.`
        );
        throw new Error(
          `Cannot to find persona by id. Passed persona id: ${selectedPersonaId.value}.`
        );
      }

      return {
        ...persona,
        img: personaImage ?? undefined,
      };
    }
  };

  const getSelectedPersona = () => {
    const selectedPersonaAndImage = getSelectedPersonaAndImage();

    if (selectedPersonaAndImage) {
      const { img, ...personaWithoutImage } = selectedPersonaAndImage;
      return personaWithoutImage;
    }
  };

  const isPersonasLoading = ref(false);

  const generatePersonas = async (input: {
    userInput: string;
    count?: number;
  }): Promise<Persona[]> => {
    selectedPersonaId.value = undefined;
    personaImages.value = undefined;
    isPersonasLoading.value = true;

    try {
      const accountId = accountStore.getSelectedAccountId();
      const { userInput, count } = input;

      const response = await api.generatePersonas({
        userInput,
        accountId,
        country: getUserCountryFromLocalStorage() ?? undefined,
        count: count ?? 3,
      });

      personas.value = response;

      if (personas.value && personas.value.length > 0) {
        setSelectedPersonaId(personas.value[0].id);
      }

      return personas.value;
    } finally {
      isPersonasLoading.value = false;
    }
  };

  const personaImages = ref<PersonaImage[] | undefined>();

  const getPersonaImages = () => {
    if (personas.value) {
      personaImages.value = personas.value.map((persona) => ({
        id: persona.id,
        status: PersonaImageStatus.PENDING,
        b64_json: null,
      }));

      personas.value.forEach(async (persona) => {
        await getPersonaImage(persona);
      });
    }
  };

  const getPersonaImage = async (persona: Persona) => {
    const personaId = persona.id ?? selectedPersonaId.value;

    if (personaId === undefined) {
      return;
    }

    if (personas.value) {
      if (!personaImages.value) {
        personaImages.value = personas.value.map((persona) => ({
          id: persona.id,
          status: PersonaImageStatus.PENDING,
          b64_json: null,
        }));
      }

      try {
        const image = await api.getPersonaImage(persona.id);
        if (personaImages.value && image) {
          const personaImage = personaImages.value.find(
            (item) => item.id === image.id
          );

          if (personaImage) {
            personaImage.b64_json = image.b64_json;
            personaImage.status = image.status;
          }
        }

        return image;
      } catch (err) {
        if (personaImages.value) {
          const personaImage = personaImages.value.find(
            (image) => image.id === persona.id
          );

          if (personaImage) {
            personaImage.status = PersonaImageStatus.FAILED;
          }
        }
        logger.error(`Failed to load image for persona ${persona.name}:`, {
          error: err,
          userInput: personaInput.value,
          persona: JSON.stringify(persona),
        });
      }
    }
  };

  const resetPersonas = () => {
    personas.value = undefined;
  };

  const isAudienceNavHeaderVisible = ref(false);

  const setIsAudienceNavHeaderVisible = (visible: boolean) => {
    isAudienceNavHeaderVisible.value = visible;
  };

  const getAudienceSegments = (audienceId: string) => {
    if (audiences.value) {
      const audience = audiences.value?.find(
        (audience) => audience.id === audienceId
      );
      if (audience) {
        return audience.segments;
      } else {
        throw new Error(`Audience ${audienceId} not found`);
      }
    } else {
      throw new Error('Audiences not loaded');
    }
  };

  const taxonomyRecommendations = ref<Audience[]>([]);
  const isTaxonomyRecommendationsLoading = ref(false);
  const isTaxonomyRecommendationsError = ref(false);
  const abortTaxonomyRecommendationsController = ref<
    AbortController | undefined
  >();

  const loadTaxonomyRecommendations = async (
    queries: string[],
    selectedLanguage?: Language
  ) => {
    isTaxonomyRecommendationsError.value = false;
    if (queries.length === 0) {
      taxonomyRecommendations.value = [];
      return;
    }

    if (abortTaxonomyRecommendationsController.value) {
      abortTaxonomyRecommendationsController.value.abort();
    }

    isTaxonomyRecommendationsLoading.value = true;
    abortTaxonomyRecommendationsController.value = new AbortController();

    try {
      const result = await api.getTaxonomyRecommendations({
        queries,
        abortSignal: abortTaxonomyRecommendationsController.value.signal,
        language: selectedLanguage,
      });

      taxonomyRecommendations.value = getTaxonomyAudiences().filter(
        (audience) => result.includes(audience.id)
      );
    } catch (err) {
      if (err instanceof RequestAborted) return;
      isTaxonomyRecommendationsError.value = true;
      taxonomyRecommendations.value = [];
      logger.error(err);
      throw err;
    } finally {
      isTaxonomyRecommendationsLoading.value = false;
    }
  };

  const clearTaxonomyRecommendations = () => {
    taxonomyRecommendations.value = [];
    isTaxonomyRecommendationsLoading.value = false;
  };

  return {
    audiences,
    deals,
    isAudiencesLoaded,
    fetchAudiencesError,
    isAudiencesMetricsLoaded,
    createDeals,
    updateDeal,
    setAudienceDeals,
    setAccountDeals,
    getAccountDeals,
    loadAudiences,
    setAudienceStatus,
    setAudienceScale,
    setAudienceOwner,
    deleteAudience,
    updateAudience,
    getAudienceDefinition,
    getAudienceDeals,
    getAudienceById,
    getLiveAudienceCount,
    getAudiencesAnalyticsWithinDays,
    getAdminOwnAudiences,
    unsetAudiences,
    getAudiences,
    getDoesAudienceExist,
    getAudienceStatus,
    getTaxonomyAudiences,
    audiencePlans,
    loadAudiencePlans,
    deleteAudiencePlan,
    tableTabs,
    isAudiencePlansLoading,
    resetAudiencePlans,
    personaInput,
    generatePersonas,
    personas,
    isPersonasLoading,
    resetPersonas,
    setPersonas,
    isAudienceNavHeaderVisible,
    setIsAudienceNavHeaderVisible,
    selectedPersonaId,
    setSelectedPersonaId,
    getPersonaImages,
    getPersonaImage,
    personaImages,
    getSelectedPersonaAndImage,
    getSelectedPersona,
    getAudienceSegments,
    taxonomyRecommendations,
    isTaxonomyRecommendationsLoading,
    isTaxonomyRecommendationsError,
    loadTaxonomyRecommendations,
    clearTaxonomyRecommendations,
  };
});
