<template>
  <aside
    class="vertical-nav"
    :class="{ 'vertical-nav--expanded': isExpanded && isVisible }"
    @mouseenter="isExpanded = true"
    @mouseleave="isExpanded = false"
  >
    <div class="vertical-nav-content">
      <template v-if="isVisible">
        <nav class="vertical-nav-tabs">
          <navbar-button :to="routes.overview">
            <nav-overview-active
              v-if="
                !isAudienceRelated &&
                !isLabsRelatedPage &&
                !isAdminRelatedPage &&
                !isMeasureRelatedPage &&
                !isPublishersRelatedPage
              "
            />
            <nav-overview v-else />Overview
          </navbar-button>

          <navbar-button
            :is-active="isAudienceRelated"
            :to="routes.audiences.table"
            data-testid="audiences-link"
          >
            <nav-audiences-active v-if="isAudienceRelated" />
            <nav-audiences v-else />
            Audiences
          </navbar-button>
          <navbar-button
            :is-active="isMeasureRelatedPage"
            :to="routes.measure.attributionPixelGroups.list"
          >
            <nav-measure-active v-if="isMeasureRelatedPage" />
            <nav-measure v-else />
            Measure
          </navbar-button>
          <navbar-button
            v-show="hasPublishersSectionAccess"
            :is-active="isPublishersRelatedPage"
            :to="routes.publishers.content"
            data-testid="publishers-nav-button"
          >
            <nav-publishers-active v-if="isPublishersRelatedPage" />
            <nav-publishers v-else />
            Publishers
          </navbar-button>
          <navbar-button
            :is-active="isLabsRelatedPage"
            :to="routes.labs.labsList"
            data-testid="labs-nav-button"
          >
            <nav-labs-active v-if="isLabsRelatedPage" />
            <nav-labs v-else />
            Labs
          </navbar-button>
          <template v-if="hasAdminSectionAccess">
            <navbar-button
              :is-active="isAdminRelatedPage"
              :to="getAdminPageRoute(permissions)"
            >
              <nav-admin-active v-if="isAdminRelatedPage" />
              <nav-admin v-else />
              Admin
            </navbar-button>
          </template>
        </nav>
        <open-audience-creation-button
          v-if="!isAudienceCreation"
          data-testid="open-audience-creation-button-vertical-nav"
          :show-title="isExpanded"
        />
      </template>
    </div>
  </aside>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import { useRoute } from 'vue-router';
import { routes } from '@/router/routes';
import { useCurrentAccountInfo, useCurrentUserInfo } from '@/composable';
import NavbarButton from './NavbarButton.vue';
import { useUserInterfaceStore } from '@/store/modules/user-interface';
import NavOverview from '@/assets/icons/colored/nav-overview.svg';
import NavAudiences from '@/assets/icons/colored/nav-audiences.svg';
import NavLabs from '@/assets/icons/colored/nav-labs.svg';
import NavMeasure from '@/assets/icons/colored/nav-measure.svg';
import NavAdmin from '@/assets/icons/colored/nav-admin.svg';
import NavOverviewActive from '@/assets/icons/colored/nav-overview_active.svg';
import NavAudiencesActive from '@/assets/icons/colored/nav-audiences_active.svg';
import NavLabsActive from '@/assets/icons/colored/nav-labs_active.svg';
import NavMeasureActive from '@/assets/icons/colored/nav-measure_active.svg';
import NavAdminActive from '@/assets/icons/colored/nav-admin_active.svg';
import OpenAudienceCreationButton from '@/components/UI/OpenAudienceCreationButton.vue';
import NavPublishers from '@/assets/icons/colored/nav-publishers.svg';
import NavPublishersActive from '@/assets/icons/colored/nav-publishers_active.svg';
import { Permission } from 'shared-types';
import { config } from '@/runtime-env';

export default defineComponent({
  name: 'VerticalNav',
  components: {
    OpenAudienceCreationButton,
    NavbarButton,
    NavOverview,
    NavAudiences,
    NavLabs,
    NavMeasure,
    NavAdmin,
    NavOverviewActive,
    NavAudiencesActive,
    NavLabsActive,
    NavMeasureActive,
    NavAdminActive,
    NavPublishers,
    NavPublishersActive,
  },
  setup() {
    const {
      isUserLoaded,
      isAdmin,
      hasAdminPermissions,
      permissions,
      accountId,
    } = useCurrentUserInfo();
    const { isAccountLoaded } = useCurrentAccountInfo();
    const userInterfaceStore = useUserInterfaceStore();

    const showNav = computed(() => {
      return userInterfaceStore.showNavbar;
    });

    const route = useRoute();
    const isDashboard = computed(() => route.meta.auth);
    const isAudienceRelated = computed(() => !!route.meta.isAudienceRelated);
    const isAdminRelatedPage = computed(() => !!route.meta.isAdminRelatedPage);
    const isLabsRelatedPage = computed(() => !!route.meta.isLabsRelatedPage);
    const isMeasureRelatedPage = computed(
      () => !!route.meta.isMeasureRelatedPage
    );
    const isPublishersRelatedPage = computed(
      () => !!route.meta.isPublishersRelatedPage
    );
    const hasPublishersSectionAccess = computed(() => {
      const userIsAdmin = isAdmin.value ?? false;
      const isInternalAccount = accountId.value === config.internalAccountId;

      return userIsAdmin || isInternalAccount;
    });
    const isAudienceCreation = computed(
      () => route.name === routes.createAudience.name
    );

    const hasAdminSectionAccess = computed(() => {
      const userIsAdmin = isAdmin.value ?? false;
      const userHasAdminPermissions = hasAdminPermissions.value ?? false;

      return userIsAdmin || userHasAdminPermissions;
    });

    const isExpanded = ref(false);

    const isVisible = computed(() => showNav.value && isAccountLoaded.value);

    const getAdminPageRoute = (permissions?: Permission[]) => {
      if (
        permissions &&
        permissions.includes(Permission.ACCOUNT_CREATE_NEW) &&
        !permissions.includes(Permission.ACCOUNT_ACCESS_ALL)
      ) {
        return routes.admin.create;
      } else {
        return routes.admin.accounts;
      }
    };

    return {
      routes,
      isDashboard,
      isUserLoaded,
      isAudienceRelated,
      showNav,
      isAdmin,
      hasAdminSectionAccess,
      isAdminRelatedPage,
      isLabsRelatedPage,
      isAccountLoaded,
      isExpanded,
      isVisible,
      isAudienceCreation,
      getAdminPageRoute,
      permissions,
      isMeasureRelatedPage,
      isPublishersRelatedPage,
      hasPublishersSectionAccess,
    };
  },
});
</script>

<style lang="scss" scoped>
// @define vertical-nav

@import '@airgrid/components/styles/shared/typography';

.vertical-nav {
  width: 56px;
  min-width: 56px;

  &-content {
    position: sticky;
    top: 48px;
    min-width: 56px;
    width: 56px;
    height: calc(100vh - 48px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;

    padding: 16px 8px;
    border-right: 1px solid var(--color-blacks-grey-60);
    background-color: var(--color-blacks-white);

    z-index: var(--z-bar);

    transition: width var(--transition-default);

    .vertical-nav--expanded & {
      width: 180px;
      box-shadow: var(--shadow-dropdown);
    }
  }

  &-tabs {
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: hidden;
    width: 40px;
    box-sizing: border-box;

    transition: width var(--transition-default);

    .vertical-nav--expanded & {
      width: 164px;
    }

    :deep(.ag-navbar__button) {
      svg {
        min-width: 16px;
        margin-right: 2px;
      }
    }
  }
}

:deep(.open-audience-creation-button) {
  position: fixed;
  bottom: 16px;
}
</style>
