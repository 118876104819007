import { AudienceErrorCodes } from '@/store/errors/audiences/errors';
import { BackendError, isBackendError } from '@/store/errors';

export const isCreateAudienceError = (
  error: unknown
): error is BackendError => {
  return (
    isBackendError(error) &&
    error.response.data.code === AudienceErrorCodes.AUDIENCE_ENTITY_CREATION
  );
};

export const isXandrDealsCreationError = (
  error: unknown
): error is BackendError => {
  return (
    isBackendError(error) &&
    error.response.data.code === AudienceErrorCodes.XANDR_DEALS_CREATION
  );
};

export const isXandrSegmentsCreationError = (
  error: unknown
): error is BackendError => {
  return (
    isBackendError(error) &&
    error.response.data.code === AudienceErrorCodes.XANDR_SEGMENTS_CREATION
  );
};

export const isAudiencePersistenceCreationError = (
  error: unknown
): error is BackendError => {
  return (
    isBackendError(error) &&
    error.response.data.code ===
      AudienceErrorCodes.AUDIENCE_PERSISTENCE_CREATION
  );
};

export const isCuratorSeatsNotFoundError = (
  error: unknown
): error is BackendError => {
  return (
    isBackendError(error) &&
    error.response.data.code === AudienceErrorCodes.CURATOR_SEATS_NOT_FOUND
  );
};

export const isMediaGridSegmentCreationError = (
  error: unknown
): error is BackendError => {
  return (
    isBackendError(error) &&
    error.response.data.code === AudienceErrorCodes.MEDIAGRID_SEGMENTS_CREATION
  );
};

export const isPubmaticSegmentCreationError = (
  error: unknown
): error is BackendError => {
  return (
    isBackendError(error) &&
    error.response.data.code === AudienceErrorCodes.PUBMATIC_SEGMENTS_CREATION
  );
};

export const isAudiencesNotFoundError = (
  error: unknown
): error is BackendError => {
  return (
    isBackendError(error) &&
    error.response.data.code === AudienceErrorCodes.AUDIENCE_NOT_FOUND
  );
};

export const isAudiencesStatisticsError = (
  error: unknown
): error is BackendError => {
  return (
    isBackendError(error) &&
    error.response.data.code === AudienceErrorCodes.AUDIENCE_STATISTICS
  );
};

export const isDeleteNotAllowedError = (
  error: unknown
): error is BackendError => {
  return (
    isBackendError(error) &&
    error.response.data.code === AudienceErrorCodes.DELETE_NOT_ALLOWED
  );
};

export const isUserNotAdminError = (error: unknown): error is BackendError => {
  return (
    isBackendError(error) &&
    error.response.data.code === AudienceErrorCodes.USER_NOT_ADMIN
  );
};

export const isAudienceDealsNotFoundError = (
  error: unknown
): error is BackendError => {
  return (
    isBackendError(error) &&
    error.response.data.code === AudienceErrorCodes.DEALS_NOT_FOUND
  );
};

export const isXandrDeleteOperationError = (
  error: unknown
): error is BackendError => {
  return (
    isBackendError(error) &&
    error.response.data.code === AudienceErrorCodes.XANDR_DELETE_OPERATION
  );
};

export const isNexusDeleteOperationError = (
  error: unknown
): error is BackendError => {
  return (
    isBackendError(error) &&
    error.response.data.code === AudienceErrorCodes.NEXUS_DELETE_OPERATION
  );
};

export const isAudienceIdRequiredError = (
  error: unknown
): error is BackendError => {
  return (
    isBackendError(error) &&
    error.response.data.code === AudienceErrorCodes.AUDIENCE_IS_REQUIRED
  );
};

export const isFeedAudienceCreationError = (
  error: unknown
): error is BackendError => {
  return (
    isBackendError(error) &&
    error.response.data.code === AudienceErrorCodes.FEED_AUDIENCE_CREATION
  );
};

export const isNoDealsForAudienceError = (
  error: unknown
): error is BackendError => {
  return (
    isBackendError(error) &&
    error.response.data.code === AudienceErrorCodes.NO_AUDIENCE_DEALS
  );
};

export const isAccountNotFoundError = (
  error: unknown
): error is BackendError => {
  return (
    isBackendError(error) &&
    error.response.data.code === AudienceErrorCodes.ACCOUNT_NOT_FOUND
  );
};

export const isReportGenerationError = (
  error: unknown
): error is BackendError => {
  return (
    isBackendError(error) &&
    error.response.data.code === AudienceErrorCodes.FAILED_CREATE_REPORT
  );
};

export const isXandrSegmentDealsCreationError = (
  error: unknown
): error is BackendError => {
  return (
    isBackendError(error) &&
    error.response.data.code === AudienceErrorCodes.XANDR_SEGMENT_DEALS_CREATION
  );
};

export const isInvalidAudienceType = (
  error: unknown
): error is BackendError => {
  return (
    isBackendError(error) &&
    error.response.data.code === AudienceErrorCodes.INVALID_AUDIENCE_TYPE
  );
};

export const isBuyerPlatformSeatNotFoundError = (
  error: unknown
): error is BackendError => {
  return (
    isBackendError(error) &&
    error.response.data.code ===
      AudienceErrorCodes.BUYER_PLATFORM_SEAT_NOT_FOUND
  );
};

export const isUnauthorizedFeedOrTaxonomyAudienceCreationError = (
  error: unknown
): error is BackendError => {
  return (
    isBackendError(error) &&
    error.response.data.code === AudienceErrorCodes.UNAUTHORIZED
  );
};

export const isAudienceDefinitionScaleIsNotTheSame = (
  error: unknown
): error is BackendError => {
  return (
    isBackendError(error) &&
    error.response.data.code === AudienceErrorCodes.AUDIENCE_SCALE_NOT_THE_SAME
  );
};

export const isTooManyRequestsError = (
  error: unknown
): error is BackendError => {
  return (
    isBackendError(error) &&
    error.response.data.code === AudienceErrorCodes.TOO_MANY_REQUESTS_ERROR
  );
};

export const isAudienceDefinitionNotFound = (
  error: unknown
): error is BackendError => {
  return (
    isBackendError(error) &&
    error.response.data.code ===
      AudienceErrorCodes.AUDIENCE_DEFINITION_NOT_FOUND
  );
};

export const isAudiencePlanNotFound = (
  error: unknown
): error is BackendError => {
  return (
    isBackendError(error) &&
    error.response.data.code === AudienceErrorCodes.AUDIENCE_PLAN_NOT_FOUND
  );
};

export const isAttributionPixelGroupNotFound = (
  error: unknown
): error is BackendError => {
  return (
    isBackendError(error) &&
    error.response.data.code ===
      AudienceErrorCodes.ATTRIBUTION_PIXEL_GROUP_NOT_FOUND
  );
};

export const isOpenAiCurrentQuotaExceededError = (
  error: unknown
): error is BackendError => {
  return (
    isBackendError(error) &&
    error.response.data.code ===
      AudienceErrorCodes.OPENAI_EXCEEDED_CURRENT_QUOTA_ERROR
  );
};

export const isOpenAiTooManyRequestsError = (
  error: unknown
): error is BackendError => {
  return (
    isBackendError(error) &&
    error.response.data.code ===
      AudienceErrorCodes.OPENAI_TOO_MANY_REQUESTS_ERROR
  );
};

export const isOpenAiInternalServerError = (
  error: unknown
): error is BackendError => {
  return (
    isBackendError(error) &&
    error.response.data.code === AudienceErrorCodes.OPENAI_INTERNAL_SERVER_ERROR
  );
};

export const isOpenAiContentPolicyViolationError = (
  error: unknown
): error is BackendError => {
  return (
    isBackendError(error) &&
    error.response.data.code ===
      AudienceErrorCodes.OPENAI_CONTENT_POLICY_VIOLATION_ERROR
  );
};

export const audienceErrorsGuard = Object.freeze({
  isCreateAudienceError,
  isXandrDealsCreationError,
  isXandrSegmentsCreationError,
  isAudiencePersistenceCreationError,
  isCuratorSeatsNotFoundError,
  isMediaGridSegmentCreationError,
  isPubmaticSegmentCreationError,
  isAudiencesNotFoundError,
  isAudiencesStatisticsError,
  isDeleteNotAllowedError,
  isUserNotAdminError,
  isAudienceDealsNotFoundError,
  isXandrDeleteOperationError,
  isNexusDeleteOperationError,
  isAudienceIdRequiredError,
  isFeedAudienceCreationError,
  isNoDealsForAudienceError,
  isAccountNotFoundError,
  isReportGenerationError,
  isXandrSegmentDealsCreationError,
  isInvalidAudienceType,
  isBuyerPlatformSeatNotFoundError,
  isUnauthorizedFeedOrTaxonomyAudienceCreationError,
  isAudienceDefinitionScaleIsNotTheSame,
  isTooManyRequestsError,
  isAudienceDefinitionNotFound,
  isAudiencePlanNotFound,
  isAttributionPixelGroupNotFound,
  isOpenAiCurrentQuotaExceededError,
  isOpenAiTooManyRequestsError,
  isOpenAiInternalServerError,
  isOpenAiContentPolicyViolationError,
});
