import { isProd, isDev } from '@/runtime-env';
import { logger } from '@/utils';
import type { OverridedMixpanel } from 'mixpanel-browser';
import { AnalyticsGalleryItem } from '@/components/Audience/Trends/AnalyticsGallery.vue';

export enum MixpanelEvent {
  SIGN_IN = 'Sign In',
  PAGE_LOAD = 'Page Load',
  HEALTH_REJECTION_REASON = 'Health Rejection Reason',
  PAGE_NOT_FOUND = 'Page Not Found',
  EXPORT_CSV_FILE = 'Export CSV File',
  PERSONA_GENERATED = 'Persona Generated',
  BRAND_PERSONAS_GENERATED = 'Brand Personas Generated',
  AUDIENCE_PLAN_CONVERTED = 'Audience Plan Converted',
  AUDIENCE_PLAN_VIEWED = 'Audience Plan Viewed',
  ANALYTICS_REPORT_VIEWED = 'Analytics Report Viewed',
  ASSISTANT_OPEN = 'Assistant Open',
  ASSISTANT_CHAT_MESSAGE_SENT = 'Assistant Chat Message Sent',
  DATA_EXPLORER_SAMPLE_SHOWN = 'Data Explorer Sample Shown',
  DATA_EXPLORER_REPORT_EXPORT = 'Data Explorer Report Export',
  CANVAS_OPEN = 'Canvas Open',
  CANVAS_NODE_ADDED = 'Canvas Node Added',
  CANVAS_AGENT_LAUNCHED = 'Canvas Agent Launched',
  CANVAS_AGENT_NODE_ADDED = 'Canvas Agent Node Added',
  BUILDER_OPENED = 'Builder Opened',
  BUILDER_CLOSED = 'Builder Closed',
  BUILDER_COMPLETED = 'Builder Completed',
  BUILDER_SUGGESTED_QUERY_SELECTED = 'Builder Suggested Query Selected',
  BUILDER_QUERY_SELECTED = 'Builder Query Selected',
  BUILDER_PERSONA_SELECTED = 'Builder Persona Selected',
  BUILDER_AUDIENCE_TYPE_SELECTED = 'Builder Audience Type Selected',
  BUILDER_APG_SELECTED = 'Builder APG Selected',
  SEARCH_MODAL_OPENED = 'Search Modal Opened',
  SEARCH_MODAL_RESULT_OPENED = 'Search Modal Result Opened',
  APG_ANALYTICS_CHART_VIEWED = 'APG Analytics Chart Viewed',
  APG_DEBUG_REASON_VIEWED = 'APG Debug Reason Viewed',
  APG_AGGREGATE_REPORT_VIEWED = 'APG Aggregate Report Viewed',
  CUSTOM_AUDIENCE_CREATION_TAB_OPENED = 'Custom Audience Creation Tab Opened',
}

export enum MixpanelSignInEventType {
  EMAIL = 'Email',
  GOOGLE = 'Google Auth',
}

export enum MixpanelAudienceAnalyticsReportViewType {
  AUDIENCE_DETAILS = 'AUDIENCE_DETAILS',
  AUDIENCE_ANALYTICS = 'AUDIENCE_ANALYTICS',
  AUDIENCE_CREATION = 'AUDIENCE_CREATION',
  AUDIENCE_PLAN = 'AUDIENCE_PLAN',
  AUDIENCE_PLAN_ACTIVATED = 'AUDIENCE_PLAN_ACTIVATED',
}

export class Tracking {
  private mixpanel: OverridedMixpanel | undefined;

  constructor() {
    this.mixpanel = undefined;
    void this.initMixpanel();
  }

  initMixpanel = async (): Promise<void> => {
    if (this.mixpanel || (!isProd && !isDev)) return;

    try {
      const mixpanelModule = await import('mixpanel-browser');
      this.mixpanel = mixpanelModule.default;

      this.mixpanel.init(GLOBALCONFIG.mixpanelToken, {
        api_host: `${GLOBALCONFIG.appBackendURL}/proxy-mp`,
        batch_requests: false,
      });
    } catch (err) {
      logger.warn('Failed to init mixpanel', err);
    }
  };

  track = (event: MixpanelEvent, data?: Record<string, unknown>): void => {
    if (!this.mixpanel) return;
    this.mixpanel.track(event, data);
  };

  identify = (userId: string, data: Record<string, unknown>): void => {
    if (!this.mixpanel) return;
    this.mixpanel.identify(userId);
    this.mixpanel.people.set(data);
  };

  registerSignIn = (params?: {
    type: MixpanelSignInEventType;
    email?: string;
    accountName?: string;
    isAdmin?: boolean;
  }): void => {
    this.track(MixpanelEvent.SIGN_IN, params);
  };

  registerPageLoad = (): void => {
    this.track(MixpanelEvent.PAGE_LOAD);
  };

  setUser = (
    id: string,
    data: {
      $email: string | undefined;
      $name: string;
    }
  ): void => {
    this.identify(id, data);
  };

  setAccount = (
    id: string,
    data: {
      accountId: string;
      accountName?: string;
      isAdmin?: boolean;
    }
  ): void => {
    this.identify(id, data);
  };

  trackPersonasUsage(
    event:
      | MixpanelEvent.PERSONA_GENERATED
      | MixpanelEvent.BRAND_PERSONAS_GENERATED,
    isUsedInBuilder: boolean
  ) {
    this.track(event, {
      isUsedInBuilder,
    });
  }

  trackAudienceAnalyticsReportUsage(
    reportType: AnalyticsGalleryItem,
    view: MixpanelAudienceAnalyticsReportViewType
  ) {
    this.track(MixpanelEvent.ANALYTICS_REPORT_VIEWED, {
      reportType,
      view,
    });
  }

  trackBuilderCompleted(payload: {
    isPlanCreated: boolean;
    isPersonaUsed: boolean;
    definition: string[];
  }) {
    this.track(MixpanelEvent.BUILDER_COMPLETED, payload);
  }
}

export const tracking = new Tracking();
