import {
  apiClient,
  isAxiosRequestAbortedError,
  isAxiosTooManyRequestsError,
} from '@/store/api/client';
import { RequestAborted } from '@/store/errors/ClientError';
import {
  Audience,
  AudienceHealthCardsReport,
  AudienceHealthDealsReport,
  DealMetric,
  Geography,
  MediaType,
  OverviewBuyerCardsReport,
  OverviewFilterOptions,
  OverviewFilterValues,
  OverviewReportByBrandItem,
  OverviewReportByDayItem,
  OverviewReportByPublisherItem,
  OverviewReportBySellerItem,
  OverviewReportType,
  RejectionReasonsReport,
} from 'shared-types';
import * as AnalyticsErrors from '@/store/errors/analytics/errors';
import { analyticsErrorGuard } from '@/store/errors/analytics/guards';
import { Persona } from '@/store/api/audiences';

export const getOverviewFilterValues = async (
  accountId: string
): Promise<OverviewFilterValues> => {
  const { data } = await apiClient.get<OverviewFilterValues>({
    url: '/reports/overview/filter-values',
    options: {
      params: {
        accountId,
      },
    },
    retryOnFail: true,
  });
  return data;
};

export interface OverviewReportByBuyerItem {
  buyerName: string;
  spend: number;
}

export const getOverviewReport = async ({
  accountId,
  reportType,
  filterOptions,
}: {
  accountId: string;
  reportType: OverviewReportType;
  filterOptions?: OverviewFilterOptions;
}): Promise<
  | OverviewReportByDayItem[]
  | OverviewReportByPublisherItem[]
  | OverviewReportBySellerItem[]
  | OverviewReportByBrandItem[]
  | OverviewReportByBuyerItem[]
> => {
  let params: Record<string, string | number | undefined> = {
    accountId,
    reportType,
  };

  if (filterOptions) {
    params = {
      ...params,
      ...filterOptions,
    };
  }

  const { data } = await apiClient.get<
    | OverviewReportByDayItem[]
    | OverviewReportByPublisherItem[]
    | OverviewReportBySellerItem[]
    | OverviewReportByBrandItem[]
  >({
    url: '/reports/overview',
    options: {
      params,
    },
    retryOnFail: true,
  });
  return data;
};

export const getOverviewCardsReport = async ({
  accountId,
  filterOptions,
}: {
  accountId: string;
  filterOptions?: OverviewFilterOptions;
}): Promise<OverviewBuyerCardsReport> => {
  let params = {
    accountId,
    reportType: 'CARDS',
  };

  if (filterOptions) {
    params = {
      ...params,
      ...filterOptions,
    };
  }

  const { data } = await apiClient.get<OverviewBuyerCardsReport>({
    url: '/reports/overview',
    options: {
      params,
    },
    retryOnFail: true,
  });
  return data;
};

export interface GetAudienceHealthReportInput {
  accountId: string;
  audienceId: string;
}

export interface AudienceHealthChartReportItem {
  day: string;
  value: number | null;
}

export interface AudienceHealthAvgBidsByDealReport {
  dealName: string;
  avgBids: AudienceHealthChartReportItem[];
}

export interface AudienceHealthBidRequestsByDealReport {
  dealName: string;
  bidRequests: AudienceHealthChartReportItem[];
}

export interface AudienceHealthSpendByDealReport {
  dealName: string;
  spend: AudienceHealthChartReportItem[];
}

export const getAudienceHealthDealsReport = async (
  input: GetAudienceHealthReportInput
): Promise<AudienceHealthDealsReport> => {
  const params = {
    ...input,
    reportType: 'DEALS',
  };

  const { data } = await apiClient.get<AudienceHealthDealsReport>({
    url: '/reports/audience-health',
    options: {
      params,
    },
    retryOnFail: true,
  });

  return data;
};

export const getAudienceHealthCardsReport = async (
  input: GetAudienceHealthReportInput
): Promise<AudienceHealthCardsReport> => {
  const params = {
    ...input,
    reportType: 'CARDS',
  };

  const { data } = await apiClient.get<AudienceHealthCardsReport>({
    url: '/reports/audience-health',
    options: {
      params,
    },
    retryOnFail: true,
  });

  return data;
};

export const getAudienceHealthSpendByDealReport = async (
  input: GetAudienceHealthReportInput
): Promise<AudienceHealthSpendByDealReport[]> => {
  const params = {
    ...input,
    reportType: 'SPEND_BY_DEAL',
  };
  const { data } = await apiClient.get<AudienceHealthSpendByDealReport[]>({
    url: '/reports/audience-health',
    options: {
      params,
    },
    retryOnFail: true,
  });

  return data;
};

export const getAudienceHealthBidRequestsByDealReport = async (
  input: GetAudienceHealthReportInput
): Promise<AudienceHealthBidRequestsByDealReport[]> => {
  const params = {
    ...input,
    reportType: 'BID_REQUESTS_BY_DEAL',
  };
  const { data } = await apiClient.get<AudienceHealthBidRequestsByDealReport[]>(
    {
      url: '/reports/audience-health',
      options: {
        params,
      },
      retryOnFail: true,
    }
  );

  return data;
};

export const getAudienceHealthAvgBidsByDealReport = async (
  input: GetAudienceHealthReportInput
): Promise<AudienceHealthAvgBidsByDealReport[]> => {
  const params = {
    ...input,
    reportType: 'AVG_BID_BY_DEAL',
  };

  const { data } = await apiClient.get<AudienceHealthAvgBidsByDealReport[]>({
    url: '/reports/audience-health',
    options: {
      params,
    },
    retryOnFail: true,
  });

  return data;
};

export interface GetAudienceConversionRateReportInput {
  accountId: string;
  audienceId: string;
}

export type AudienceConversionRateByDealResponse = {
  dealName: string;
  dailyCvrs: {
    day: string;
    cvr: number;
    cumulativeCvr: number;
  }[];
}[];

export const getAudienceConversionRateByDealReport = async (
  input: GetAudienceConversionRateReportInput
): Promise<AudienceConversionRateByDealResponse> => {
  const { data } = await apiClient.get<AudienceConversionRateByDealResponse>({
    url: '/reports/feed-audience-conversion-rate-by-deal',
    options: {
      params: input,
    },
    retryOnFail: true,
  });

  return data;
};
export type AudienceHealthBlockedDomainsReport = {
  domain: string;
  predictedImpressions: number;
}[];

export const getAudienceHealthBlockedDomainsReport = async (
  input: GetAudienceHealthReportInput
): Promise<AudienceHealthBlockedDomainsReport> => {
  const params = {
    ...input,
    reportType: 'BLOCKED_DOMAINS',
  };

  const { data } = await apiClient.get<AudienceHealthBlockedDomainsReport>({
    url: '/reports/audience-health',
    options: {
      params,
    },
    retryOnFail: true,
  });

  return data;
};

export const getRejectionReasonsReport = async (
  dealId: number
): Promise<RejectionReasonsReport> => {
  try {
    const { data } = await apiClient.get<RejectionReasonsReport>({
      url: '/reports/rejection-reasons',
      options: {
        params: { dealId },
      },
      retryOnFail: true,
    });

    return data;
  } catch (err) {
    if (analyticsErrorGuard.isNoRejectionReasonForDealIdError(err)) {
      throw new AnalyticsErrors.NoRejectionReasonsForDealId(err);
    }
    throw err;
  }
};

export type TrendReport = {
  label: 'historical' | 'forecast';
  day: string;
  query: string;
  score: number;
}[];

export const getTrendReport = async (input: {
  queries: string[];
  abortSignal?: AbortSignal;
}): Promise<TrendReport> => {
  try {
    const { abortSignal, ...params } = input;
    const { data } = await apiClient.get<TrendReport>({
      url: '/reports/trends',
      options: {
        params,
        signal: abortSignal,
        cache: true,
      },
      retryOnFail: true,
    });

    return data;
  } catch (err) {
    if (isAxiosRequestAbortedError(err)) {
      throw new RequestAborted();
    }
    throw err;
  }
};

export type DomainReport = {
  domain: string;
  queries: Record<string, string | number>;
}[];

export const getDomainReport = async (input: {
  queries: string[];
  abortSignal?: AbortSignal;
}): Promise<DomainReport> => {
  try {
    const { abortSignal, ...params } = input;

    const { data } = await apiClient.get<DomainReport>({
      url: '/reports/domain',
      options: {
        params,
        signal: abortSignal,
        cache: true,
      },
    });

    return data;
  } catch (err) {
    if (isAxiosRequestAbortedError(err)) {
      throw new RequestAborted();
    }
    throw err;
  }
};

export type DeviceReport = {
  query: string;
  device: string;
  score: number;
}[];

export const getDeviceReport = async (input: {
  queries: string[];
  abortSignal?: AbortSignal;
}): Promise<DeviceReport> => {
  try {
    const { abortSignal, ...params } = input;

    const { data } = await apiClient.get<DeviceReport>({
      url: '/reports/device',
      options: {
        params,
        signal: abortSignal,
        cache: true,
      },
      retryOnFail: true,
    });

    return data;
  } catch (err) {
    if (isAxiosRequestAbortedError(err)) {
      throw new RequestAborted();
    }
    throw err;
  }
};

export type BrowserReport = {
  query: string;
  browser: string;
  score: number;
}[];

export const getBrowserReport = async (input: {
  queries: string[];
  abortSignal?: AbortSignal;
}): Promise<BrowserReport> => {
  try {
    const { abortSignal, ...params } = input;

    const { data } = await apiClient.get<BrowserReport>({
      url: '/reports/browser',
      options: {
        params,
        signal: abortSignal,
        cache: true,
      },
      retryOnFail: true,
    });

    return data;
  } catch (err) {
    if (isAxiosRequestAbortedError(err)) {
      throw new RequestAborted();
    }
    throw err;
  }
};

export enum CountryCodes {
  GBR = 'gbr',
  USA = 'usa',
  CAN = 'can',
  AUS = 'aus',
  FRA = 'fra',
  DEU = 'deu',
  ITA = 'ita',
}

export type GeoReport = {
  country: string;
  region: string;
  iso_3166_2: string;
  queries: Record<string, string | number>;
}[];

export const getGeoReport = async (input: {
  queries: string[];
  countryCode: string;
  abortSignal?: AbortSignal;
}): Promise<GeoReport> => {
  try {
    const { abortSignal, ...params } = input;

    const { data } = await apiClient.get<GeoReport>({
      url: '/reports/geo',
      options: {
        params,
        signal: abortSignal,
        cache: true,
      },
      retryOnFail: true,
    });

    return data;
  } catch (err) {
    if (isAxiosRequestAbortedError(err)) {
      throw new RequestAborted();
    }
    throw err;
  }
};

export type GraphReport = {
  query: string;
  nodes: {
    query: string;
    strength: number;
    isFrom: boolean;
  }[];
}[];

export const getGraphReport = async (input: {
  queries: string[];
  abortSignal?: AbortSignal;
}): Promise<GraphReport> => {
  try {
    const { abortSignal, ...params } = input;
    const { data } = await apiClient.get<GraphReport>({
      url: '/reports/graph',
      options: {
        params,
        signal: abortSignal,
        cache: true,
      },
      retryOnFail: true,
    });

    return data;
  } catch (err) {
    if (isAxiosRequestAbortedError(err)) {
      throw new RequestAborted();
    }
    throw err;
  }
};

export type HourReport = {
  query: string;
  hour: string;
  score: number;
}[];

export const getHourReport = async (input: {
  queries: string[];
  abortSignal?: AbortSignal;
}): Promise<HourReport> => {
  try {
    const { abortSignal, ...params } = input;

    const { data } = await apiClient.get<HourReport>({
      url: '/reports/hour',
      options: {
        params,
        signal: abortSignal,
        cache: true,
      },
      retryOnFail: true,
    });

    return data;
  } catch (err) {
    if (isAxiosRequestAbortedError(err)) {
      throw new RequestAborted();
    }
    throw err;
  }
};

export type ChromeTopicReport = {
  chromeTopic: string;
  queries: Record<string, string | number>;
}[];

export const getChromeTopicReport = async (input: {
  queries: string[];
  abortSignal?: AbortSignal;
}): Promise<ChromeTopicReport> => {
  try {
    const { abortSignal, ...params } = input;

    const { data } = await apiClient.get<ChromeTopicReport>({
      url: '/reports/chrome-topic',
      options: {
        params,
        signal: abortSignal,
        cache: true,
      },
      retryOnFail: true,
    });

    return data;
  } catch (err) {
    if (isAxiosRequestAbortedError(err)) {
      throw new RequestAborted();
    }
    throw err;
  }
};

export type YoutubeChannelsReport = {
  queries: Record<string, number>;
  channelId: string;
  channelName: string;
  channelCustomUrl: string;
  channelThumbnail: string;
  channelCategory: string;
}[];

export const getYoutubeChannelsReport = async (input: {
  queries: string[];
  isDownload?: boolean;
  abortSignal?: AbortSignal;
}): Promise<YoutubeChannelsReport> => {
  try {
    const { abortSignal, ...params } = input;

    const { data } = await apiClient.get<YoutubeChannelsReport>({
      url: '/reports/youtube-channels',
      options: {
        params,
        signal: abortSignal,
        cache: true,
      },
      retryOnFail: true,
    });

    return data;
  } catch (err) {
    if (isAxiosRequestAbortedError(err)) {
      throw new RequestAborted();
    }
    throw err;
  }
};

export interface UrlsReport {
  url: string;
  title: string;
  queries: Record<string, number>;
}

export const getUrlsReport = async (input: {
  queries: string[];
  abortSignal?: AbortSignal;
}): Promise<UrlsReport[]> => {
  try {
    const { abortSignal, ...params } = input;

    const { data } = await apiClient.get<UrlsReport[]>({
      url: '/reports/urls',
      options: {
        params,
        signal: abortSignal,
        cache: true,
      },
      retryOnFail: true,
    });

    return data;
  } catch (err) {
    if (isAxiosRequestAbortedError(err)) {
      throw new RequestAborted();
    }
    throw err;
  }
};

export enum AudiencePlanReport {
  TRENDS = 'TRENDS',
  DOMAIN = 'DOMAIN',
  CHROME_TOPICS = 'CHROME_TOPICS',
  BROWSER = 'BROWSER',
  HOUR = 'HOUR',
  DEVICE = 'DEVICE',
  GEO = 'GEO',
  GRAPH = 'GRAPH',
  URLS = 'URLS',
  SUGGESTED_AUDIENCES = 'SUGGESTED_AUDIENCES',
  YOUTUBE_CHANNELS = 'YOUTUBE_CHANNELS',
}

export interface AudiencePlanResponse {
  id: string;
  accountId: string;
  mediaType: MediaType;
  metric: DealMetric;
  geographies: Geography[];
  viewabilityRate?: number;
  completionRate?: number;
  queries: string[];
  trendReport?: TrendReport;
  chromeTopicReport?: ChromeTopicReport;
  domainReport?: DomainReport;
  browserReport?: BrowserReport;
  hourReport?: HourReport;
  deviceReport?: DeviceReport;
  youtubeChannelsReport?: YoutubeChannelsReport;
  geoReport?: GeoReport;
  graphReport?: GraphReport;
  audienceName: string | null;
  createdAt: string;
  urlsReport?: UrlsReport[];
  persona?: Persona;
  scale:
    | {
        uniques: number;
        impressions: number;
      }
    | undefined;
  suggestedAudiences?: Audience[];
  isAudienceCreated: boolean;
  plannedAudienceId?: string;
}

export const getAudiencePlan = async (input: {
  hash: string;
  countryCode?: string;
  reports?: AudiencePlanReport[];
}): Promise<AudiencePlanResponse | null> => {
  try {
    const { data } = await apiClient.get<AudiencePlanResponse | null>({
      url: `/reports/audience-plan?hash=${input.hash}`,
      options: {
        allowUnauthenticated: true,
        params: {
          reports: input.reports ?? Object.keys(AudiencePlanReport),
          countryCode: input.countryCode,
        },
      },
    });
    return data;
  } catch (err) {
    if (isAxiosTooManyRequestsError(err)) {
      throw new AnalyticsErrors.AudiencePlanRateLimitError();
    } else if (analyticsErrorGuard.isFailedToDecodeHashError(err)) {
      return null;
    }
    throw err;
  }
};

export interface DimensionDataItem {
  value: string;
  metric: string | number;
  item: string;
}

export interface DimensionItem {
  dimension: string;
  friendlyName: string;
  data: DimensionDataItem[];
}

export interface MetricDataItem {
  hour: string;
  [key: string]: string | number;
}

export enum MetricValueType {
  CURRENCY = 'currency',
  PERCENTAGE = 'percentage',
  COUNT = 'count',
}

export interface MetricMetadata {
  name: string;
  friendlyName: string;
  totalValue: number;
  benchmarkValue: number;
  type: MetricValueType;
}

export interface MetricItem {
  metadata: MetricMetadata[];
  data: MetricDataItem[];
}

export interface PerformanceReportResponse {
  table: string;
  dimensions: DimensionItem[];
  metrics: MetricItem;
}

export interface PerformanceReportFilter {
  dimensionFilters?: DimensionFilter[];
  timeFilter?: { days: number };
  table?: string;
  metric?: string;
}

export type DimensionFilter = { dimension: string } & (
  | { in: string[] | number[]; notIn?: never }
  | { in?: never; notIn: string[] | number[] }
);

export interface PerformanceTablesResponse {
  name: string;
  friendlyName: string;
}

export const getPerformanceReportTables = async (accountId: string) => {
  const { data } = await apiClient.get<{
    result: PerformanceTablesResponse[];
  }>({
    url: '/reports/performance-tables',
    options: {
      params: {
        accountId,
      },
    },
  });

  return data.result;
};

export const getPerformanceReport = async ({
  accountId,
  audienceId,
  filter,
  abortSignal,
}: {
  accountId: string;
  audienceId: string;
  filter?: PerformanceReportFilter;
  abortSignal?: AbortSignal;
}) => {
  try {
    const payload = {
      audienceId,
      accountId,
      ...filter,
    };
    const { data } = await apiClient.post<{
      result: PerformanceReportResponse;
    }>({
      url: '/reports/performance',
      data: payload,
      options: {
        signal: abortSignal,
      },
    });

    return data.result;
  } catch (err) {
    if (isAxiosRequestAbortedError(err)) {
      throw new RequestAborted();
    }
    throw err;
  }
};

export enum AttributionPixelGroupDownloadReportType {
  DEBUG = 'DEBUG',
  NORMAL = 'NORMAL',
}

export interface AttributionPixelGroupDownloadReportRequest {
  attributionPixelGroupId: number;
  accountId: string;
}

export interface AttributionPixelGroupDownloadReportResponse {
  attributionPixelGroupId: number;
  hour: string;
  conversions: number;
  debugConversions: number;
  impressions: number;
  dsp: string;
}

export const getAttributionPixelGroupDownloadReport = async (
  input: AttributionPixelGroupDownloadReportRequest
): Promise<AttributionPixelGroupDownloadReportResponse[]> => {
  try {
    const { data } = await apiClient.get<
      AttributionPixelGroupDownloadReportResponse[]
    >({
      url: '/reports/attribution-pixel-group-download',
      options: {
        params: {
          ...input,
        },
      },
    });

    return data;
  } catch (err) {
    if (analyticsErrorGuard.isInvalidReportTypeError(err)) {
      throw new AnalyticsErrors.InvalidReportTypeError(err);
    }
    throw err;
  }
};

export interface PublishersPageViewsByDayItem {
  day: string;
  cnt: number;
}

export interface PublishersPageViewsByCountryItem {
  country: string;
  cnt: number;
}

export type PublishersPageViews = Record<
  string,
  {
    byDay: PublishersPageViewsByDayItem[];
    byCountry: PublishersPageViewsByCountryItem[];
  }
>;

export enum PublisherPageViewsReportType {
  BY_DAY = 'byDay',
  BY_COUNTRY = 'byCountry',
}

export async function getPublisherPageViewsReports(): Promise<PublishersPageViews> {
  const { data } = await apiClient.get<PublishersPageViews>({
    url: '/reports/publisher-page-views',
    retryOnFail: true,
    options: {
      params: {
        reportTypes: [
          PublisherPageViewsReportType.BY_DAY,
          PublisherPageViewsReportType.BY_COUNTRY,
        ],
      },
    },
  });

  return data;
}

export enum DataExplorerFeature {
  DOMAINS = 'domains',
  YOUTUBE = 'youtube',
  POST_CODES = 'post_codes',
}

export interface AudienceDataExplorerRequest {
  queries: string[];
  feature: DataExplorerFeature;
  countryCode: string | null;
  limit: number;
  isCombined: boolean;
}

export interface DomainsExportReport {
  domain: string;
  score: number;
  value: string;
}

export interface GeoExportReport {
  country: string;
  postalCode: string;
  score: number;
  value: string;
}

export interface YoutubeChannelsExportReport {
  channelId: string;
  channelName: string;
  channelCustomUrl: string;
  channelCategory: string;
  score: number;
  value: string;
}

export type AudienceDataExplorerReport =
  | DomainsExportReport[]
  | YoutubeChannelsExportReport[]
  | GeoExportReport[];

export const getAudienceDataExplorerReport = async (
  input: AudienceDataExplorerRequest,
  abortSignal?: AbortSignal
) => {
  try {
    const { data } = await apiClient.get<AudienceDataExplorerReport>({
      url: '/labs/run-data-explorer',
      options: {
        params: input,
        signal: abortSignal,
      },
    });

    return data;
  } catch (err) {
    if (isAxiosRequestAbortedError(err)) {
      throw new RequestAborted();
    }
    throw err;
  }
};
